import React from 'react'
import GalleryGrid from '../../components/mobile/GalleryGrid'
import { useRouteMatch } from 'react-router-dom'

export default function Catalogo() {
  
  const routeMatch = useRouteMatch();
  const slideName = routeMatch ? routeMatch.params.category : "";
  
  return !routeMatch || !routeMatch.isExact ? null : (
    <GalleryGrid slideName={slideName} />
  )
}
