import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from "recoil";
import { getSelectedItemState, mobileAnimationState } from "../../state/catalogState";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { chunk } from "../../utils";
import { CSSTransition } from "react-transition-group";
import MobileShareModal from './MobileShareModal';

import { Helmet } from "react-helmet";

import "./itemDescription.css";
import { useParams, useHistory } from 'react-router-dom';
import { logShare } from "../../utils/analytics";

export default function ItemDescription(props) {

  let history = useHistory();
  const params = useParams();
  const item = useRecoilValue(getSelectedItemState(params)) || { item: { id: 1 } };
  const [animationInProp, setAnimationInProp] = useRecoilState(mobileAnimationState);
  const [shareModalOpen, setShareModalOpen] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setAnimationInProp(true);
    }, 200);
  }, [params, setAnimationInProp])

  const renderCharacteristics = () => {
    const characteristics = chunk(item.characteristics, 2);

    return (
      <>
        {characteristics.map(pair => (
          <CharacteristicWrapper key={pair[0][0]}>
            <Characteristic>
              {pair[0][0]}: {pair[0][1]} <br />
              {pair[1][0]}: {pair[1][1]}
            </Characteristic>
          </CharacteristicWrapper>
        ))}
      </>
    )
  }

  const handleMobileShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${item.title} - Germinia`,
        text: 'Me gustaría recomendarte este producto en Germinia: ',
        url: document.URL
      }).then(() => {
        console.log('Thanks for sharing!');
        logShare("Mobile Share");
      })
        .catch(console.error);
    } else {
      setShareModalOpen(true);
    }
  }

  const goToContact = () => {
    console.log("hey")
    history.push(`/contacto`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <title>{`${item.title} - Germinia`}</title>
        <meta name="description" content={item.description} />
        <meta property="og:title" content={`${item.title} - Germinia`} />
        <meta property="og:description" content={item.description} />
        <meta property="og:url" content={`https://germinia.es/catalogo/${params.category}/${params.id}`} />
        <meta property="og:image" content={item.imageURLConFondo} />
        <meta property="og:site_name" content="Germinia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={`${item.title} - ${params.category} - Germinia`} />
      </Helmet>
      <CSSTransition in={animationInProp} timeout={1000} classNames="mobile-description" appear mountOnEnter unmountOnExit >
        <ItemDesc>
          <Description dangerouslySetInnerHTML={{ __html: item.description }} />
          <Buttons>
            <Row>
              <Button onClick={goToContact}>Contacto</Button>
              <Button onClick={handleMobileShare}>
                Compartir
            <ShareIcon icon={faShareAlt} />
              </Button>
            </Row>
            {/* <Button color={item.sabor ? item.sabor[1] : ""}>Sabor: {item.sabor ? item.sabor[0] : ""}</Button> */}
            <Button color={item.disponibilidad ? item.disponibilidad[1] : ""}>Disponibilidad: {item.disponibilidad ? item.disponibilidad[0] : ""}</Button>
          </Buttons>
          <CharacteristicsList>
            {renderCharacteristics()}
          </CharacteristicsList>
        </ItemDesc>
      </CSSTransition>
      <MobileShareModal open={shareModalOpen} setModalOpen={setShareModalOpen} />
    </>
  )
}

const ItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  font-size: 2.3em;
  font-family: "Montserrat";
`;

const Description = styled.div`
  margin: 2em 0 2em;
  padding: 1.5em 2em;
  font-family: "Montserrat";
  font-weight: 300;
  line-height: 1.6;
  /* box-shadow: 0px 4px 35px -6px #07401861; */
  box-shadow: 0px 8px 80px -24px #07401861;
  color: #1d330d;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;

const Button = styled.div`
  padding: 0.5em 1em;
  background: ${props => props.color || "#4B7D00"};
  cursor: ${props => !props.color ? "pointer" : "unset"};
  color: white;
  margin: .5em 0;
  box-shadow: 0 7px 20px -7px #627962;
  font-weight: 500;
  ${props => !props.color ? "background-image: linear-gradient(180deg, #8BC34A,#558B2F);" : null};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0em 2em;
`;

const ShareIcon = styled(FontAwesomeIcon)`
  margin-left: 1em;
`;

const CharacteristicsList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  font-weight: 300;
`;

const CharacteristicWrapper = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 1.5em 0;
  display: flex;
  padding-left: 64px;
  /* justify-content: center; */
`;


const Characteristic = styled.div`
  border-left: 1px solid black;
  padding-left: 1em;
`;