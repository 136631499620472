import ReactGA from 'react-ga';

ReactGA.initialize('UA-3154697-25');

// const logEvent = (category, action) => {
//   ReactGA.event({
//     category,
//     action,
//   });
// }

const logEvent = category => action => {
  ReactGA.event({
    category,
    action,
  });
}

export const logShare = logEvent("Share");

export default ReactGA;