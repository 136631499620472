import React from 'react'
import styled from 'styled-components';

export default function ElOrigen() {
  return (
    <Container>

      <ImageContainer>
        <Image src="https://static-germinia-es.web.app/assets/brotes-elOrigen.png" alt="brotes image" />
      </ImageContainer>
      <TitleContainer>

        <Title className="titulo-elOrigen">elOrigen</Title>

      </TitleContainer>

      <TextContainer>
        <div>
          <AboutText className="texto-elOrigen">
            <b> Germinia</b> nace en una pequeña empresa familiar con una larga tradición en el sector de la agricultura. El cariño y el buen hacer de su abuelo Juan quedó impregnado en el carácter de Rafa y Amparo, que aprendieron desde pequeños a respetar la agricultura. Después de años de ensayos de diferentes especies con biólogos y pruebas de calidad con diferentes cocineros, <b> Germinia </b>empieza su recorrido de la mano de dos hermanos, un ingeniero agrícola y una naturópata apasionada por la comida sana y la buena mesa, convencidos de que la agricultura libre de pesticidas y productos químicos es posible. Una agricultura que aprovecha el clima mediterráneo para ofrecer un producto de gran calidad.
          </AboutText>
          <Signature className="elOrigen-quote">“Del campo a la mesa”</Signature>
        </div>
      </TextContainer>

    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: row;
  /* margin: 3em 4em 0; */
  margin: 5vh auto 15vh;
  width: 80%;
  background-color: black;
  color: white;
`;

const ImageContainer = styled.div`
  width: 48%;
  background-image: url("https://static-germinia-es.web.app/assets/brotes-elOrigen.png");
  background-size: cover;

`;

const Image = styled.img`
  /* width: calc(100vw - 8em); */
  width: 100%;
  display: none;
`;

const TitleContainer = styled.div`
  display: flex;
  /* padding: 4em 0;
  margin-bottom: 2em; */
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  border: none;
  width: 10%;
`;

const Title = styled.h1`
  font-family: "Caviar Dreams";
  margin: 0;
  font-size: 10vh;
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  width: 42%;
  border-left: 2px white solid;
  height: 100%;
  margin-bottom: 0;
`;

const AboutText = styled.p`
  margin: 2em 3em;
  line-height: 1.8;
  font-size: 1vw;
`;

const Signature = styled.p`
  font-family: "Caviar Dreams";
  margin: 1em 0;
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
`;