import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import ItemDescription from "./ItemDescription";
import styled from "styled-components";

function ItemDetails(props) {

  const params = useParams();

  return !useRouteMatch() ? null : (
    <DetailsContainer>
      <ItemDescription {...params} />
    </DetailsContainer>
  );
}

const DetailsContainer = styled.div`
  min-width: 60vw;
  /* z-index: -1; */
`;


export default ItemDetails;
