import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import {
  RecoilRoot,
} from 'recoil';
import { CSSTransition } from "react-transition-group";
import { default as MobileLandingPage } from "./pages/mobile/LandingPage";
import { default as MobileElOrigenPage } from "./pages/mobile/ElOrigen";
import { default as MobileContactoPage } from "./pages/mobile/Contacto";
import { default as MobileCookiesPage } from "./pages/mobile/Cookies";
import { default as MobileAvisoLegalPage } from "./pages/mobile/AvisoLegal";
import { default as MobileCatalogos } from "./pages/mobile/Catalogos";
import { default as MobileCatalogo } from "./pages/mobile/Catalogo";
import { default as MobileProductDetail } from "./pages/mobile/ProductDetail";
import { default as DesktopLandingPage } from "./pages/desktop/LandingPage";
import { default as DesktopElOrigenPage } from "./pages/desktop/ElOrigen";
import { default as DesktopContactoPage } from "./pages/desktop/Contacto";
import { default as DesktopCookiesPage } from "./pages/desktop/Cookies";
import { default as DesktopAvisoLegalPage } from "./pages/desktop/AvisoLegal";
import { default as DesktopCatalogos } from "./pages/desktop/Catalogos";
import { default as DesktopCatalogo } from "./pages/desktop/Catalogo";
import { default as DesktopProductDetail } from "./pages/desktop/ProductDetail";
import "./styles.css";
import GlobalFonts from "./assets/fonts"
import { hasTouchFeature } from "./utils/screen";
import MobileHeader from "./components/mobile/MobileHeader";
import DesktopHeader from "./components/desktop/DesktopHeader";
import MobileFooter from "./components/mobile/MobileFooter";
import DesktopFooter from "./components/desktop/DesktopFooter";

function Gallery() {
  const [useMobileCatalogo] = useState(hasTouchFeature());
  
  const LandingPage = useMobileCatalogo ? MobileLandingPage : DesktopLandingPage;
  const ElOrigenPage = useMobileCatalogo ? MobileElOrigenPage : DesktopElOrigenPage;
  const ContactoPage = useMobileCatalogo ? MobileContactoPage : DesktopContactoPage;
  const CookiesPage = useMobileCatalogo ? MobileCookiesPage : DesktopCookiesPage;
  const AvisoLegalPage = useMobileCatalogo ? MobileAvisoLegalPage : DesktopAvisoLegalPage;
  const Catalogos = useMobileCatalogo ? MobileCatalogos : DesktopCatalogos;
  const Catalogo = useMobileCatalogo ? MobileCatalogo : DesktopCatalogo;
  const ProductDetail = useMobileCatalogo ? MobileProductDetail : DesktopProductDetail;
  const Header = useMobileCatalogo ? MobileHeader : DesktopHeader;
  const Footer = useMobileCatalogo ? MobileFooter : DesktopFooter;

  const routes = [
    { path: "/catalogo/:category/:id", url: "/catalogo/Brotes/1", name: "Details Page", Component: ProductDetail },
    { path: "/catalogo/:category/", url: "/catalogo/Brotes/", name: "Catalog Page", Component: Catalogo },
    { path: "/el-origen", url: "/el-origen", name: "elOrigen", Component: ElOrigenPage },
    { path: "/catalogos", url: "/catalogos", name: "Catálogo", Component: Catalogos },
    { path: "/contacto", url: "/contacto", name: "Contacto", Component: ContactoPage },
    { path: "/cookies", url: "/cookies", name: "Cookies", Component: CookiesPage },
    { path: "/aviso-legal", url: "/aviso-legal", name: "Aviso Legal", Component: AvisoLegalPage },
    { path: "/", url: "/", name: "Inicio", Component: LandingPage },
    // { path: "/", url: "/", name: "Inicio", Component: Catalogos },
  ];

  return (
    <RecoilRoot>
      <GlobalFonts />
      <Router>
          <Header />
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={300}
                    classNames="page"
                    unmountOnExit
                    appear
                  >
                    <div className="page">
                      <Component match={match} />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            ))}
        <Footer />
      </Router>
    </RecoilRoot>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Gallery />, rootElement);
