import React, { useState } from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faMobileAlt, faComment } from '@fortawesome/free-solid-svg-icons'

// import * as firebase from 'firebase/app';
import { functions } from './../../utils/firebase';
import FormFeedback from '../../components/mobile/FormFeedback';

export default function Contacto() {

  const [formState, setFormState] = useState({
    nombre: "",
    telefono: "",
    email: "",
    mensaje: ""
  });

  const [showFeedback, setShowFeedback] =
    useState({
      show: false,
      // show: true,
      status: false
    });

  const handleInputChange = ({ target: { name, value } }) => {
    setFormState((state) => ({ ...state, [name]: value }))
  }

  const handleFormSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const checkbox = document.querySelector("#conditions-checkbox")
    if (!checkbox.checked) {
      setShowFeedback({ show: true, status: "missingCheck" });
      setTimeout(() => {
        setShowFeedback({ show: false, status: false })
      }, 4000);
      return false;
    }
    const data = { ...formState, acepto: checkbox.checked }

    const callable = functions.httpsCallable('contactEmail');
    callable(data).then(res => {
      console.log(res.data.success)
      res.data.success
        ? setShowFeedback({ show: true, status: "success" })
        : setShowFeedback({ show: true, status: "error" })

      console.log(showFeedback);

      setTimeout(() => {
        res.data.success && setFormState({
          nombre: "",
          telefono: "",
          email: "",
          mensaje: ""
        })
        checkbox.checked = false;
        setShowFeedback({ show: false, status: false })
      }, 6000);
    })
  }

  return (
    <Container>
      <ContactForm onSubmit={handleFormSubmit}>
        <SectionHeader>
          <Logo
            src="https://static-germinia-es.web.app/assets/logos/germinia-logo-flor.svg"
            alt="Logo Flor"
          />
          <Header>Contacto</Header>
        </SectionHeader>
        <InputGroup>
          <Icon icon={faUser} />
          <Input required validate type="text" value={formState["nombre"]} onChange={handleInputChange} placeholder="Nombre" name="nombre" />
        </InputGroup>
        <InputGroup>
          <Icon icon={faMobileAlt} />
          <Input
            required
            type="tel"
            value={formState["telefono"]}
            onChange={handleInputChange}
            placeholder="Telefono"
            name="telefono"
            pattern="[\d\-_\(\)\+]{9,20}"
          />
        </InputGroup>
        <InputGroup>
          <Icon icon={faEnvelope} />
          <Input required validate type="email" value={formState["email"]} onChange={handleInputChange} placeholder="Email" name="email" />
        </InputGroup>
        <InputGroup>
          <Icon icon={faComment} />
          <TextArea rows={4} value={formState["mensaje"]} onChange={handleInputChange} placeholder="Mensaje" name="mensaje" />
        </InputGroup>
        <Conditions>
          <ConditionsCheckbox type="checkbox" name="conditions" id="conditions-checkbox" />
          <ConditionsLabel for="conditions-checkbox" >Al marcar, <b>acepto</b> compartir las respuestas de mi formulario.</ConditionsLabel>
        </Conditions>
        {/* <SubmitBtn type="submit" disabled={showFeedback.show} onClick={handleFormSubmit} value="Enviar" /> */}
        <SubmitBtn type="submit" disabled={showFeedback.show} value="Enviar" />
      </ContactForm>
      {/* <Snackbar open={showFeedback.show} autoHideDuration={6000} onClose={handleClose}>
        <Alert style={{ fontSize: "4vw" }} onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar> */}
      {showFeedback.show && (
        <FormFeedback status={showFeedback.status} form={formState} />
      )}
      <ContactInfo>
        <SectionHeader>
          <Logo
            src="https://static-germinia-es.web.app/assets/logos/germinia-logo.svg"
            alt="Logo Germinia"
          />
          <Header>Encuéntranos</Header>
        </SectionHeader>
        <Direccion>Pol. ind. H - Camino C2 </Direccion>
        <Direccion>46800 Xàtiva · Valencia</Direccion>
        <Direccion>Tel.: 962287492 - 671661044</Direccion>
        <Direccion>ayuda@germinia.es</Direccion>
        <Map>
          <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12403.697815856105!2d-0.500246!3d38.9942205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a299afd859ea350!2sGerminia!5e0!3m2!1sen!2suk!4v1599503887093!5m2!1sen!2suk" width="800" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0" class="mapa"></iframe>
        </Map>
      </ContactInfo>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3em 4em 0;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 3em 0;
`;

const Header = styled.h1`
  font-size: 7vw;
  margin: 0;
  margin-left: .5em;
  font-weight: 400;
  letter-spacing: 1px;
`;

const Logo = styled.img`
  height: 90px;
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3em;
  /* border: 1px #e0e4e1 solid; */
  box-shadow: 13px 19px 34px 6px #ecedee, -3px -3px 6px #fff;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.5em;
  padding: 30px;
  color: #A1BC7B;
  min-width: 1em;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  padding-left: 0;
  outline: none;
  border: none;
  font-size: 4vw;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: .6em;
  padding-left: 0;
  outline: none;
  border: none;
  font-size: 4vw;
`;

const Conditions = styled.div`
  display: flex;
  align-items: center;
`;

const ConditionsCheckbox = styled.input`
  width: 3em;
  height: 3em;
  margin-right: 1.5em;
  background-color: white;
`;

const ConditionsLabel = styled.label`
  font-size: 2.5vw;
`;

const SubmitBtn = styled.input`
  font-size: 4vw;
  background-color: #769645;
  color: white;
  padding: 24px 20px;
  border: none;
  cursor: pointer;
  width: 50%;
  opacity: 0.9;
  margin: 2em;
  align-self: center;
  box-shadow: 13px 19px 34px 6px #ecedee, -3px -3px 6px #fff;
  -webkit-appearance: none;
`;

const Direccion = styled.p`
  margin: 0 0 .5em;
  font-size: 4vw;
`;

const Map = styled.div`
  margin-top: 2em;
  align-self: center;
`;