import React from 'react'
import styled from 'styled-components';

export default function AvisoLegal() {
  return (
    <Container>
      <SectionTitle>Aviso Legal</SectionTitle>
      <div>
        <p>Los datos identificativos y personales proporcionados en el marco del presente sitio web son confidenciales y formarán parte del fichero titularidad de Germinia COOP. V. Al mismo tiempo, aceptar los terminos y condiciones, autoriza a Germinia COOP. V. a enviarles información comercial sobre las novedades de la tienda.</p>
        {/* <p>El usuario deberá rellenar obligatoriamente todos los campos de los formularios marcados con asteriscos con la finalidad de poder tramitar la compra. Los datos introducidos en los formularios por el usuario deberán ser correctos para procesar el envío. Germinia COOP. V. cumple con la Ley Orgánica de Protección de Datos 15/1999 que contiene entre sus principios generales el principio de calidad de los datos que, ligado al principio de proporcionalidad de los datos, exige que los mismos sean adecuados a la finalidad que motiva su recogida.</p> */}
        <p>Germinia COOP. V. se compromete a cumplir la obligación de secreto respecto de los contenidos en el fichero y nunca venderá o alquilará dicha información a terceros. Asimismo, el titular de los datos de carácter personal tendrá derecho de oposición, acceso, rectificación y cancelación respecto a cuantos datos suyos existan almacenados en la base de datos de Germinia COOP. V. remitiendo un correo electrónico a ayuda@germinia.es.</p>
        <p>Conforme a lo establecido en la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal, el cumplimiento de cualquier formulario existente en el sitio web www.germinia.es / https://www.facebook.com/germinia.es/ o el envío de un correo electrónico a <a href="mailto:ayuda@germinia.es">ayuda@germinia.es</a> implica la aceptación de esta política de protección de datos. Por el simple hecho de navegar por la web los visitantes no facilitan información personal alguna, ni quedan obligados a facilitarla.</p>
        <p>Los clientes y usuarios se comprometen a navegar por la página web y a utilizar el contenido de forma correcta, sin llevar a cabo ningún tipo de acción fraudulenta sobre la misma.</p>
      </div>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3em 20vw 0;
  /* font-size: 2vw; */
  line-height: 1.7;

  & a {
    color: #759645;
  }
`;

const SectionTitle = styled.h3`
  margin-top: 2em;
  color: #526930;
`;