import React from 'react'
import styled from 'styled-components';
import { default as Catalogos } from "./Catalogos";
import { NavLink } from "react-router-dom";

export default function LandingPage({match}) {

  return match && match.isExact ? (
    <section>
      <ContainerHero>
        <BrotesImg
          src="https://static-germinia-es.web.app/assets/landing-page-brotes-image.png"
          alt="brotes image"
        />

        <HeroCopy>
          <H1>Inspirados por la naturaleza</H1>
          <img
            src="https://static-germinia-es.web.app/assets/logos/germinia-logo-text-green.svg"
            alt="germinia-logo"
            style={{ width: "95%" }}
          />
          <SubHead>
            Flores comestibles y brotes tiernos de cultivo orgánico
            </SubHead>

          <Buttons>
            <CTAButton to="/contacto"   >
              Contacto
            </CTAButton>
            {/* <a
              href="catalogo.html"
              class="cta-visita neumorphic-shadows show-desktop hide-mobile"
              id="visit-catalog"
            >Visitar nuestro Catalogo</a
            > */}
          </Buttons>
        </HeroCopy>
      </ContainerHero>
      <Catalogos match={match} />
    </section>
  ) : null;
}

const ContainerHero = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px 0;
  align-items: center;
  margin-bottom: -100px;
`;

const BrotesImg = styled.img`
  width: 90%;
`;

const HeroCopy = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const H1 = styled.h1`
  font-family: "Caviar Dreams";
  font-size: 3.2em;
  margin: 0;
  margin-top: 1em;
`;

const SubHead = styled.p`
  font-family: "Caviar Dreams";
  font-size: 1.9em;
  margin-top: -5%;
  text-align: right;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0px;
  margin-top: 20px;
  justify-content: center;
`;

const CTAButton = styled(NavLink)`
  background: #A1BC7B;
  width: 40%;
  display: block;
  color: white;
  padding: .5em;
  text-decoration: none;
  font-size: 2.8em;
  margin: 3% auto 7%;
  position: relative;
  text-align: center;
  box-shadow: 0px 8px 16px -3px #a0bc7bbf, -3px -3px 6px #fff;
`;