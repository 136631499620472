import React, { useEffect } from 'react'
import styled from 'styled-components';

import GerminiaLogo from "../../assets/images/logos/germinia-logo-text-black.svg";
import DrawerMenu from './DrawerMenu';
import { useHistory } from 'react-router-dom';

export default function MobileHeader() {
  let history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`)
    })
  }, [history])

  return (
    <Container>
      <Logo
        onClick={() => handleLogoClick()}
        src={GerminiaLogo}
        alt="Germinia Logo"
      />
      <DrawerMenu />
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2em 3em;
`;

const Logo = styled.img`
  width: 33vw;
  z-index: 100;
`;