import React from 'react';
import { useParams, Route, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getCatalogItemsState } from "../../state/catalogState";
import ItemDetails from "../../components/desktop/ItemDetails";
// import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import DetailsGrid from '../../components/desktop/DetailsGrid';

function ProductDetail(props) {
  const { category } = useParams();
  let { path } = useRouteMatch() || { path: "/null/", url: "/null/" };

  const items = useRecoilValue(getCatalogItemsState(category));

  return (
    <Container>
      <Route exact path={path}>
        <ItemDetails />
      </Route>

      <DetailsGrid items={items} slideName={category} />

    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;


export default ProductDetail;