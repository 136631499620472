import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


export default function FormFeedback(props) {

  const { status, form: { nombre, telefono, email, mensaje } } = props

  const emailSubject = `Germinia contacto`;

  const emailBody = encodeURIComponent(
    `Hola,

${mensaje.trim()}${mensaje[mensaje.lenght] === "." ? "" : "."}

${nombre}
${email}
${telefono}
`);

  const icon = status === "success"
    ? faCheckCircle
    : faExclamationCircle

  const message = () =>
    status === "success"
      ? "Gracias por contactar con nosotros. Nos pondremos en contacto contigo lo antes posible."
      : status === "missingCheck"
        ? "Se requiere aceptación para completar su petición."
        : (
          <>
            No se pudo enviar la solicitud, haga clic <a style={{ color: "white", fontWeight: 500 }} rel="noopener noreferrer" href={`mailto:?to=ayuda@germinia.es&subject=${emailSubject}&body=${emailBody}`} target="_blank" > aquí</a> para enviar el correo electrónico manualmente.
          </>
        )

  const color = status === "success"
    ? "#2f9c2f"
    : "#d20d0d"

  return (
    <SnackBar color={color}>
      <Icon icon={icon} />
      <Message>{message()}</Message>
    </SnackBar>
  )
}

const SnackBar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  padding: 1em 2.2em;
  border-radius: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2em;
  color: white;
  margin-right: .5em;
`;

const Message = styled.div`
  color: white;
  font-size: .8vw;
`;