import React, { useState } from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import DrawerMenu from "./DrawerMenu";
import MobileShareModal from "./MobileShareModal";

export default function MobileFooter() {

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const handleMobileShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `Germinia`,
        text: 'Me gustaría recomendarte esta pagina en Germinia: ',
        url: document.URL
      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch(console.error);
    } else {
      setShareModalOpen(true);
    }
  }

  return (
    <Footer>
      <FooterLeft>
        <Icon
        onClick={handleMobileShare}
        size="xl"
        icon={faShareAlt}
        />
      </FooterLeft>

      <FooterCenter>
        <Icons>
          <IconLink target="blank" rel="noreferrer" href="https://www.facebook.com/germinia.es/">
            <Icon size="xl" icon={faFacebook} />
          </IconLink>
          <IconLink target="blank" rel="noreferrer" href="https://www.instagram.com/germinia_es/">
            <Icon size="xl" icon={faInstagramSquare} />
          </IconLink>
        </Icons>
      </FooterCenter>

      <FooterRight>
        <DrawerMenu />
      </FooterRight>
      <MobileShareModal open={shareModalOpen} setModalOpen={setShareModalOpen} />
    </Footer>
  )
}

const Footer = styled.footer`
  -webkit-appearance: none;
  box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
  width: calc(80% - 6em);
  /* height: 8vh; */
  padding: 24px unset;
  background-color: rgb(250, 250, 250);
  border: 1px solid #edeef1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-left: 10%;
  margin-top: 5vh;
  padding: 2em 3em;
  display: flex;
  justify-content: space-around;
  align-content: center;
`;

const FooterLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 4em;
`;

const IconLink = styled.a`
  color: black;
  text-decoration: none;
`;
