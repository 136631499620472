import React from 'react';

import GerminiaSVG from "../../assets/images/logos/germinia-logo.svg"

export default function Germinia(props) {
  
  return (
    <img src={GerminiaSVG} width={90} height={90} alt="Germinia" />
  )
}
