import React from 'react'
import styled from 'styled-components';

export default function ElOrigen() {
  return (
    <Container>

      <div className="container-elOrigen-image">
        <Image src="https://static-germinia-es.web.app/assets/brotes-elOrigen.png" alt="brotes image" className="brotes-elOrigen-image hide-desktop" />
      </div>
      <TitleContainer>

        <Title className="titulo-elOrigen">elOrigen</Title>

      </TitleContainer>

      <div className="container-elOrigen-textos">
        <div>
          <AboutText className="texto-elOrigen">
            <b> Germinia</b> nace en una pequeña empresa familiar con una larga tradición en el sector de la agricultura. El cariño y el buen hacer de su abuelo Juan quedó impregnado en el carácter de Rafa y Amparo, que aprendieron desde pequeños a respetar la agricultura. Después de años de ensayos de diferentes especies con biólogos y pruebas de calidad con diferentes cocineros, <b> Germinia </b>empieza su recorrido de la mano de dos hermanos, un ingeniero agrícola y una naturópata apasionada por la comida sana y la buena mesa, convencidos de que la agricultura libre de pesticidas y productos químicos es posible. Una agricultura que aprovecha el clima mediterráneo para ofrecer un producto de gran calidad.
          </AboutText>
          <Signature className="elOrigen-quote">“Del campo a la mesa”</Signature>
        </div>
      </div>

    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3em 4em 0;
  background-color: black;
  color: white;
`;

const Image = styled.img`
  width: calc(100vw - 8em);
`;

const TitleContainer = styled.div`
  display: flex;
  padding: 4em 0;
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
  border-bottom: 5px white solid;
`;

const Title = styled.h1`
  font-family: "Caviar Dreams";
  margin: 0;
  font-size: 15vw;
  text-align: center;
`;

const AboutText = styled.p`
  margin: 2em 2em;
  line-height: 2;
  font-size: 3.5vw;
`;

const Signature = styled.p`
  font-family: "Caviar Dreams";
  margin: 2em 0;
  text-align: center;
  font-size: 5vw;
  font-weight: 600;
`;