import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CatalogTitleDesktop } from '../Typography';
import GridItem from './GridItem';

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    // [theme.breakpoints.down('sm')]: {
    //   position: 'absolute',
    // },
  },
  images: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function GalleryGrid(props) {
  const { classes, slideName, items } = props;
  return (
    <div className={classes.root}>
      <CatalogTitleDesktop >{slideName}</CatalogTitleDesktop>
      {/* <CatalogTitleDesktop style={{ marginLeft: 48, marginRight: 48 }}>{slideName}</CatalogTitleDesktop> */}
      <div className={classes.images}>
        {items.map((item, i) => (
          i < 7 && <GridItem slideName={slideName} index={i} key={i} item={item} />
        ))}
        {items.length >= 8 && <GridItem
          item={{
            imageURLConFondo:
              '',
            title: 'Ver mas',
            width: '25%',
            id: 999
          }}
          slideName={slideName} index={999}
        />}
      </div>
    </div>
  );
}


export default withStyles(styles)(GalleryGrid);
