import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import analytics, { logShare } from '../../utils/analytics';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileShareModal({ open, setModalOpen }) {
  const pageUrl = encodeURIComponent(document.URL);

  const handleClose = () => {
    setModalOpen(false);
  };

  const emailSubject = `Me gustaría recomendarte este producto en germinia.es`;

  const emailBody = `Hola,%0D%0A%0D%0A
Me gustaría recomendarte este producto en germinia.es%0D%0A%0D%0A
${pageUrl}
`

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ fontSize: "2.5em" }}
    >
      <DialogTitle id="alert-dialog-slide-title">Comparte esta página</DialogTitle>
      <DialogContent>
        <Container>
          <ShareButton
            rel="noopener noreferrer"
            // href={"https://www.facebook.com/sharer.php?u=" + pageUrl}
            to={"https://www.facebook.com/sharer.php?u=" + pageUrl}
            eventLabel="Facebook Share - Mobile"
            target="_blank"
            style={{ background: "#3B5998" }}
            onClick={() => logShare("Facebook - Mobile")}
          >
            <ShareIcon size="sm" icon={faFacebookF} />
            <ShareLabel>Facebook</ShareLabel>
          </ShareButton>
          <ShareButton
            rel="noopener noreferrer"
            // href={"https://twitter.com/intent/tweet?url=" + pageUrl}
            to={"https://twitter.com/intent/tweet?url=" + pageUrl}
            eventLabel="Twitter Share - Mobile"
            target="_blank"
            style={{ background: "#55ACEE" }}
            onClick={() => logShare("Twitter - Mobile")}
          >
            <ShareIcon icon={faTwitter} />
            <ShareLabel>Twitter</ShareLabel>
          </ShareButton>
          <ShareButton
            rel="noopener noreferrer"
            // href={"https://www.linkedin.com/sharing/share-offsite/?url=" + pageUrl}
            to={"https://www.linkedin.com/sharing/share-offsite/?url=" + pageUrl}
            eventLabel="LinkedIn Share - Mobile"
            target="_blank"
            style={{ background: "#2867B2" }}
            onClick={() => logShare("LinkedIn - Mobile")}
          >
            <ShareIcon icon={faLinkedin} />
            <ShareLabel>LinkedIn</ShareLabel>
          </ShareButton>
          <ShareButton
            rel="noopener noreferrer"
            // href={`mailto:?subject=${emailSubject}&body=${emailBody}`}
            to={`mailto:?subject=${emailSubject}&body=${emailBody}`}
            eventLabel="Email Share - Mobile"
            target="_blank"
            style={{ background: "#4B7D00" }}
            onClick={() => logShare("Email - Mobile")}
          >
            <ShareIcon icon={faEnvelope} />
            <ShareLabel>Email</ShareLabel>
          </ShareButton>
        </Container>
        {/* <DialogContentText id="alert-dialog-slide-description">
          Let Google help apps determine location. This means sending anonymous location data to
          Google, even when no apps are running.
          </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   place-items: center;
//   /* font-size: 2.2em; */
// `;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const DialogTitle = styled.h3`
  /* font-size: 2.5em; */
  margin-left: 20px;
`;

// const ShareButton = styled.a`
const ShareButton = styled(analytics.OutboundLink)`
  margin-bottom: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  padding: 1em;
  /* color: #777; */
  text-align: center;
  /* font-size: 14px; */
  /* font-weight: 500; */
  line-height: 1.1;
  /* letter-spacing: 2px; */
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  /* border: 1px solid #ddd; */
  cursor: pointer;
`;

const ShareIcon = styled(FontAwesomeIcon)`
  color: white;
  /* margin-right: 1em; */
`;

const ShareLabel = styled.span`
  letter-spacing: 1px;
  color: white;
`;