import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getCatalogItemsState, desktopAnimationState } from '../../state/catalogState';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    // overflow: 'hidden',
    marginTop: "3em",
  },
  gridList: {
    flexWrap: 'wrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    justifyContent: "center",
  },
  title: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: .6,
    fontWeight: 300
  },
  titleBar: {
    background: "#0408069e"
    // 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));


export default function CatalogoGrid(props) {
  const classes = useStyles();
  const { slideName, view } = props;
  const history = useHistory();

  const items = useRecoilValue(getCatalogItemsState(slideName || "Brotes"));
  const setAnimationInProp = useSetRecoilState(desktopAnimationState);

  const routeMatch = useRouteMatch();

  const isExact = routeMatch ? routeMatch.isExact : false;

  const handleItemClick = (item) => {
    setAnimationInProp(false);
    item.id === 999
      ? history.push(`/catalogo/${slideName}`)
      : history.push(`/catalogo/${slideName}/${item.id}`);
    window.scrollTo(0, 0);
  };

  return isExact ? (
    <div className={classes.root}>
      <List cellHeight={view === "landing" ? "250" : Math.floor(window.innerWidth / 6)} spacing={6} view={view} cols={view === "landing" ? 6 : Math.floor(window.innerWidth / 250)}>
        {/* <GridList cellHeight={"200"} spacing={6} className={classes.gridList} cols={Math.ceil(items.length / 2) > 8 ? 8 : Math.ceil(items.length / 2)}> */}
        {items.slice(0, view === "landing" ? 8 : items.length).map((item) => (
          <GridListTile
            cols={1}
            rows={1}
            key={item.id}
            onClick={() => handleItemClick(item)}
          >
            <img src={item.imageURLConFondo} alt={item.title} />
            <GridListTileBar
              title={item.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
        ))}
      </List>
    </div>
  ) : null;
}

const List = styled(GridList)`
    flex-wrap: wrap;
    /* Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. */
    transform: translateZ(0);
    justify-content: ${props => props.view !== "landing" ? "flex-start" : "center"};
    cursor: pointer;
`;