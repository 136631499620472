import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { CSSTransition } from "react-transition-group";
import { useHistory, useParams } from "react-router-dom";
import "./detailsGrid.css";
import { useSetRecoilState } from 'recoil';
import { desktopAnimationState } from '../../state/catalogState';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  // gridList: {
  //   width: "100%",
  //   // height: "90vh",
  //   height: `calc(100vh - ${headerHeight}px)`,
  // },
  gridTile: {
    height: "15vw !important",
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

export default function DetailsGrid(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const setAnimationInProp = useSetRecoilState(desktopAnimationState);
  // const items = useRecoilValue(getCatalogItemsState(category));

  let { slideName, items } = props
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInProp(true)
    }, 100);
    return () => {
      setInProp(false);
    }
  }, [slideName])

  items = items || [];

  const handleItemClick = (item) => {
    // Prevent navigation to same item
    if (document.URL.includes(`/catalogo/${slideName}/${item.id}`)) {
      return false;
    }
    setAnimationInProp(false);
    setTimeout(() => {
      item.id === 999
        ? history.push(`/catalogo/${slideName}`)
        : history.push(`/catalogo/${slideName}/${item.id}`);
      window.scrollTo(0, 0);
    }, 300);
  };

  return (
    <CSSTransition in={inProp} timeout={1000} classNames="details-grid" appear mountOnEnter unmountOnExit >
      <div className={classes.root}>
        <GridItemList cellHeight={180}>
          {items.map((item) => (
            <GridListTile
              key={item.id}
              className={classes.gridTile}
              onClick={() => handleItemClick(item)}
            >
              <img src={item.imageURLConFondo} alt={item.title} />
              {Number(item.id) !== Number(params.id) ? <GridListTileBar
                title={item.title}
                style={{
                  color: "white",
                  textAlign: "center",
                  textTransform: "uppercase",
                  letterSpacing: .6,
                  fontWeight: 300,
                }}
              /> : null}
            </GridListTile>
          ))}
        </GridItemList>
      </div>
    </CSSTransition>
  );
}

const GridItemList = styled(GridList)`
    width: 100%;
    /* height: 90vh; */
    height: calc(100vh - 96px);
`;