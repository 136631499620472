import React, { useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import GerminiaLogo from "../../assets/images/logos/germinia-logo-text-black.svg";
import analytics from "../../utils/analytics";
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-3154697-25');

export default function DesktopHeader() {
  let history = useHistory();
  const url = document.URL;

  const handleLogoClick = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    return history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`)
      analytics.pageview(location.pathname);
    })
  }, [history])

  const routes = [
    { path: "/", name: "Inicio" },
    { path: "/el-origen", name: "elOrigen" },
    { path: "/catalogos", name: "Catálogo" },
    { path: "/contacto", name: "Contacto" },
  ];

  const iconURL = (path, name) => (
    url.includes(path)
      ? null
      : name === "Contacto"
        ? "https://static-germinia-es.web.app/assets/logos/germinia-logo-flor.svg"
        : name === "Catálogo"
          ? "https://static-germinia-es.web.app/assets/logos/germinia-logo.svg"
          : null
  )

  return (
    <Container id="desktop-header">
      <Logo
        onClick={() => handleLogoClick()}
        src={GerminiaLogo}
        alt="Germinia Logo"
      />

      <NavLinks>
        {routes.map(({ path, name }) => (
          <HeaderLink
            key={path}
            // as={NavLink}
            to={path}
            icon={iconURL(path, name)}
          >
            {name}
          </HeaderLink>
        ))}
      </NavLinks>

    </Container>
  )
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0em 3em;
  height: 130px;
  align-items: center;
`;

const Logo = styled.img`
  width: min(25vw, 256px);
  /* width: 256px; */
  height: min-content;
  z-index: 100;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  z-index: 100;
`;

const HeaderLink = styled(NavLink)`
  cursor: pointer;
  font-size: 1.2rem;
  /* margin-left: 5vw; */
  padding: .5em 1em;
  background-color: #ffffff5c;
  color: black;
  text-decoration: none;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;