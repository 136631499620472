import React from 'react'
import CatalogoGrid from '../../components/desktop/CatalogoGrid'
import { useRouteMatch } from 'react-router-dom'
import { CatalogTitleDesktop } from "../../components/Typography";

export default function Catalogo() {
  
  const routeMatch = useRouteMatch();
  const slideName = routeMatch ? routeMatch.params.category : "";
  
  return routeMatch && routeMatch.isExact && (
    <>
    <CatalogTitleDesktop style={{ marginLeft: 48, marginRight: 48 }}>{slideName}</CatalogTitleDesktop>
    <CatalogoGrid slideName={slideName} view={"catalogo"} />
    </>
  )
}
