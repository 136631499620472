import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "../Typography";
import { useHistory } from "react-router-dom";
import { desktopAnimationState } from '../../state/catalogState';
import { useSetRecoilState } from "recoil";

const styles = theme => ({
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    border: "2px white solid",
    height: "20vw",
    // height: "40vh",
    "&:hover": {
      zIndex: 1
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15
    },
    "&:hover $imageMarked": {
      opacity: 0
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
      backgroundColor: "#00000080",
    }
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
    textTransform: "uppercase",
    // backgroundColor: "black",
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
});

function GridItem(props) {
  const { classes, item, slideName, index } = props;
  const setAnimationInProp = useSetRecoilState(desktopAnimationState);

  const history = useHistory();

  const handleItemClick = () => {
    setAnimationInProp(false);
    setTimeout(() => {
      item.id === 999
      ? history.push(`/catalogo/${slideName}`)
      : history.push(`/catalogo/${slideName}/${index}`);
      window.scrollTo(0, 0);
    }, 300);
  };

  return (
    <ButtonBase
      onClick={() => handleItemClick()}
      key={item.title}
      className={classes.imageWrapper}
      style={{
        width: item.width
      }}
    >
      <div
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${item.imageURLConFondo})`
        }}
      />
      <div className={classes.imageBackdrop} />
      <div className={classes.imageButton}>
        <Typography
          component="h3"
          variant="h6"
          color="inherit"
          className={classes.imageTitle}
        >
          {item.title}
          <div className={classes.imageMarked} />
        </Typography>
      </div>
    </ButtonBase>
  );
}

GridItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GridItem);
