import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

export default function DesktopFooter() {
  return (
    <Footer>
      <FooterLeft>
        <NeaImage src="https://static-germinia-es.web.app/assets/logos/nea-icon.png" alt="nea-icon" />
        <Link href="https://neacreatives.com">By NEA creatives</Link>
      </FooterLeft>

      <FooterCenter>
        <Icons>
          <Link href="https://www.facebook.com/germinia.es/">
            <Icon icon={faFacebook} />
          </Link>
          <Link href="https://www.instagram.com/germinia_es/">
            <Icon icon={faInstagramSquare} />
          </Link>
        </Icons>

        <Copyright>Copyright Germinia 2020</Copyright>

      </FooterCenter>

      <FooterRight>
        <H3>Enlaces de interés</H3>
        <Link href="./aviso-legal">Politica de privacidad</Link>
        <Link href="./cookies">Politica de cookies</Link>
      </FooterRight>
    </Footer>
  )
}

const Footer = styled.footer`
  box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
  width: calc(80% - 6em);
  /* height: 8vh; */
  background-color: rgb(250, 250, 250);
  border: 1px solid #edeef1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-left: 10%;
  margin-top: 5vh;
  padding: .5em 3em 1em;
  display: flex;
  justify-content: space-around;
  align-content: center;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 15%;
`;

const FooterCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 65%;
`;

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 20%;
`;

const NeaImage = styled.img`
  width: 3vw;
  margin-bottom: 0.8em;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2em;
`;

const Link = styled.a`
  color: black;
  text-decoration: none;
  /* margin-top: 0.7em; */
`;

const Copyright = styled.p`
  font-size: 1em;
  margin: 0;
  margin-top: 1em;
`;

const H3 = styled.h3`
    font-weight: 600;
  font-size: 1em;
  color: #0F0F0F;
  margin-bottom: 10px;
`;