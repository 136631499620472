import React from 'react';
import GalleryGrid from '../../components/desktop/GalleryGrid';
import {
  useRecoilState,
} from 'recoil';

import { catalogsState } from "../../state/catalogState";

function Catalogos(props) {

  const [catalogs] = useRecoilState(catalogsState);

  return props.match && props.match.isExact ? (
    <>
      {Object.keys(catalogs).map(catalogo => <GalleryGrid view="landing" items={catalogs[catalogo]} slideName={catalogo} key={catalogo} />)}
    </>
  ) : null;
}


export default Catalogos;