import React from 'react'
import styled from 'styled-components';
import { default as Catalogos } from "./Catalogos";
import { NavLink } from 'react-router-dom';

export default function LandingPage({ match }) {

  return match && match.isExact ? (
    <Section>
      <ContainerHero>
        <BrotesImg
          src="https://static-germinia-es.web.app/assets/landing-page-brotes-image.png"
          alt="brotes image"
        />

        <HeroCopy>
          <H1>Inspirados por la naturaleza</H1>
          <img
            src="https://static-germinia-es.web.app/assets/logos/germinia-logo-text-green.svg"
            alt="germinia-logo"
            style={{ width: "95%" }}
          />
          <SubHead>
            Flores comestibles y brotes tiernos de cultivo orgánico
            </SubHead>

          <Buttons>
            <CTAButton to="/contacto">
              Contacto
            </CTAButton>
            <CatalogoButton
              to="/catalogos"
            >
              Visitar nuestro Catalogo
            </CatalogoButton>
          </Buttons>
        </HeroCopy>
      </ContainerHero>
      <div className="scroll"></div>
      <Catalogos match={match} />
    </Section>
  ) : null;
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const ContainerHero = styled.div`
  display: flex;
  width: 90%;
  margin: 50px auto 0;
  align-items: center;
  margin-bottom: 0px;

`;

const BrotesImg = styled.img`
  width: 90%;
  @media(min-width: 650px) {
    width: 45%;
  }
`;

const HeroCopy = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const H1 = styled.h1`
  font-family: "Caviar Dreams";
  font-size: 3vw;
  margin: 0;
  margin-top: 1em;
  text-align: right;
`;

const SubHead = styled.p`
  font-family: "Caviar Dreams";
  font-size: 1.7vw;
  margin-top: -5%;
  text-align: right;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0px;
  margin-top: 20px;
  /* justify-content: center; */
  justify-content: space-evenly;
  align-items: center;
`;

const CTAButton = styled(NavLink)`
  background: #A1BC7B;
  width: max-content;
  display: block;
  color: white;
  padding: .5em 1em;
  text-decoration: none;
  /* font-size: 1.2em; */
  font-size: 1.6vw;
  /* margin: 3% auto 7%; */
  position: relative;
  text-align: center;
  box-shadow: 0px 8px 16px -3px #a0bc7bbf, -3px -3px 6px #fff;
`;

const CatalogoButton = styled(CTAButton)`
  background-color: #769645;
`;