import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "../Typography";
import {
  useHistory
} from "react-router-dom";
import { useLocalStorage } from '@rehooks/local-storage';

function GlideItem(props) {

  const { classes, slideName, item, index } = props;
  let history = useHistory();

  let [currentItems, setCurrentItems] = useLocalStorage(
    "current-item",
    {
      "Brotes": 1,
      "Flores": 1,
      "Hojas": 1,
    })

  const handleItemClick = () => {
    history.push(`/catalogo/${slideName}/${index}`);
    currentItems[slideName] = index;
    setCurrentItems(currentItems);
    window.scrollTo(0, 0);
  };

  return (
    <ButtonBase
      onClick={() => handleItemClick()}
      key={item.index}
      className={classes.imageWrapper}
      style={{
        width: "100%"
      }}
      id={`${slideName}-${index}`}
    >
      <div
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${item.imageURLConFondo})`
        }}
      />
      <div className={classes.imageButton}>
        <Typography
          component="h3"
          variant="h2"
          color="inherit"
          className={classes.imageTitle}
        >
          {item.title}
        </Typography>
      </div>
    </ButtonBase>
  );
}

const styles = theme => ({
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    border: "2px white solid",
    width: "100% !important",
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    placeItems: "center",
    width: "100%",
    padding: `${theme.spacing(4)}px 0`,
    backgroundColor: "#0408069e",
    fontSize: "3.00em",
    fontWeight: 300,
    letterSpacing: 0.4,
    textAlign: "center",
    textTransform: "uppercase",
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
});


GlideItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GlideItem);
