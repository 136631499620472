import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useRecoilState } from 'recoil';
import { mobileDrawerState } from '../../state/catalogState';
import { MenuLinkMobile } from "../Typography";
// import FlorLight from "../icons/FlorLight";
// import GerminiaLight from "../icons/GerminiaLight";
import Flor from "../icons/Flor";
import Germinia from "../icons/Germinia";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';

export default function DrawerMenu() {
  const [drawerState, setDrawerState] = useRecoilState(mobileDrawerState);
  const url = document.URL;

  const routes = [
    { path: "/", name: "Inicio" },
    { path: "/el-origen", name: "elOrigen" },
    { path: "/catalogos", name: "Catálogo" },
    { path: "/contacto", name: "Contacto" },
  ];

  const displayIcon = (path, name) => (
    url.includes(path)
      ? null
      : name === "Contacto" ? <Flor /> : name === "Catálogo" ? <Germinia /> : null
  )

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState(open);
  };

  const list = () => (
    <Drawer
      // style={{ width: "60vw" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div>
        <CloseButton>
          <CloseMenuIcon icon={faTimes} />
        </CloseButton>
        <List>
          {routes.map(({ path, name }) => (
            <DrawerLink key={path} to={path}>
              <ListItem button>
                <DrawerListIcon>{displayIcon(path, name)}</DrawerListIcon>
                <MenuLinkMobile>
                  {name}
                </MenuLinkMobile>
              </ListItem>

            </DrawerLink>
          ))}
        </List>
      </div>
      <BottomLinks>
        <List>
          <DrawerLink to={"/aviso-legal"}>
            <ListItem button>
              <DrawerListIcon></DrawerListIcon>
              <MenuLinkMobile
                style={{ margin: 0, fontSize: "2em" }}
              >
                {"Politica de privacidad"}
              </MenuLinkMobile>
            </ListItem>
          </DrawerLink>
          <DrawerLink to={"/cookies"}>
            <ListItem button>
              <DrawerListIcon></DrawerListIcon>
              <MenuLinkMobile
                style={{ margin: 0, fontSize: "2em" }}
              >
                {"Politica de cookies"}
              </MenuLinkMobile>
            </ListItem>
          </DrawerLink>
        </List>
      </BottomLinks>
    </Drawer>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <OpenMenuIcon icon={faBars} size="xl" />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={drawerState}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
}

const Drawer = styled.div`
  width: 60vw;
  /* background-color: #769645; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DrawerListIcon = styled.div`
  display: inline-flex;
  min-width: 90px;
  flex-shrink: 0;
  margin: 0 1.5em;
`;

const OpenMenuIcon = styled(FontAwesomeIcon)`
  color: #769645;
  font-size: 4em;
  `;

const CloseMenuIcon = styled(FontAwesomeIcon)`
  color: #769645;
  /* color: white; */
  font-size: 5em;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 3em 3em 0;
`;

const DrawerLink = styled(NavLink)`
  color: black;
  text-decoration: none;
`;

const BottomLinks = styled.div`
  
`;