import React from "react";
import styled from "styled-components";

import Glide from 'react-glidejs';
import DetailsGlideItem from "./DetailsGlideItem";
// import 'react-glidejs/dist/index.css';
import "./GlideCarouselTransitions.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getCatalogItemsState, mobileAnimationState } from "../../state/catalogState";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from '@rehooks/local-storage';

const GlideContainer = styled.div`
  margin: 2em 0px;
  max-height: 80vw;
`;

export default ({ slideName }) => {
  let history = useHistory();
  const items = useRecoilValue(getCatalogItemsState(slideName || "Brotes"));
  const setAnimationInProp = useSetRecoilState(mobileAnimationState);
  

  const [currentItems, setCurrentItems] = useLocalStorage(
    "current-item",
    {
      "Brotes": 1,
      "Flores": 1,
      "Hojas": 1,
    });

  const currentItem = currentItems ? currentItems[slideName] : currentItems[slideName] === 0 ? 0 : 1;
  const gliderRef = React.useRef(null);

  return (
    <>
      <GlideContainer id={slideName}>
        <Glide
          ref={gliderRef}
          throttle={25}
          type="carousel"
          customSlideAnimation={{
            timeout: 100,
            classNames: 'fade',
          }}
          peek={{
            before: 90,
            after: 90,
          }}
          gap={-70}
          perView={1}
          startAt={currentItem}
          slideClassName="slider__frame"
          onSwipeEnd={(e) => {
            setAnimationInProp(false);
            currentItems[slideName] = gliderRef.current.index;
            
            setCurrentItems(currentItems);
            setTimeout(() => {
              history.push(`/catalogo/${slideName}/${gliderRef.current.index}`);
            }, 200);
          }}
        >
          {items.map((item, i) => (
            <DetailsGlideItem key={i} index={i} slideName={slideName} item={item} />
          ))}
        </Glide>
      </GlideContainer>
    </>
  );
};
