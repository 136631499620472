import React from "react";
import styled from "styled-components";

import Glide from 'react-glidejs';
import GlideItem from "./GlideItem";
// import 'react-glidejs/dist/index.css';
import "./GlideCarouselTransitions.css";
import { useRecoilValue } from "recoil";
import { getCatalogItemsState } from "../../state/catalogState";
import { useLocalStorage } from '@rehooks/local-storage';

const GlideContainer = styled.div`
  margin: 2em 0px;
  /* max-height: max(100%, 80vw); */
  max-height: 80vw;
`;

export default ({ slideName }) => {
  const items = useRecoilValue(getCatalogItemsState(slideName || "Brotes"));

  const [currentItems] = useLocalStorage(
    "current-item",
    {
      "Brotes": 1,
      "Flores": 1,
      "Hojas": 1,
    });

  const currentItem = currentItems ? currentItems[slideName] : currentItems[slideName] === 0 ? 0 : 1;
  const gliderRef = React.useRef(null);
  
  return (
    <GlideContainer id={slideName}>
      <Glide
        ref={gliderRef}
        throttle={25}
        type="carousel"
        customSlideAnimation={{
          timeout: 100,
          classNames: 'fade',
        }}
        peek={{
          before: 90,
          after: 90,
        }}
        gap={-70}
        perView={1}
        startAt={currentItem}
        slideClassName="slider__frame"
      // focusAt="center"
      >
        {items.map((item, i) => (
          <GlideItem key={i} index={i} slideName={slideName} item={item} />
        ))}
      </Glide>
    </GlideContainer>
  );
};
