import {
  atom,
  // selector,
  selectorFamily,
} from 'recoil';


const catalogsState = atom({
  key: 'catalogsState', // unique ID (with respect to other atoms/selectors)
  default: {
    Brotes: [
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/acelga_amarilla_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/acelga_amarilla_3.png',
        title: 'Acelga Amarilla',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "30g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Tierra húmeda", "#878e3d"],
        disponibilidad: ["Septiembre a Junio", "#006600"],
        description: "Aroma a tierra húmeda, dulce, de agradable sabor y textura suave a la vez que crujiente. ",
        id: 0
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/acelga_roja_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/acelga_roja_3.png',
        title: 'Acelga Roja',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "30g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Tierra húmeda", "#878e3d"],
        disponibilidad: ["Septiembre a Junio", "#006600"],
        description: "Aroma a tierra húmeda, dulce, de agradable sabor y textura suave a la vez que crujiente. ",
        id: 1
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/albahaca.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/albahaca.png',
        title: 'Albahaca Basil',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Limón, aní­s", "#878e3d"],
        disponibilidad: ["Abril a Septiembre", "#006600"],
        description: "Potente desde su origen, limón, aní­s y resina. La increible combinación de matices que presenta, ayudan a realzar tanto platos de verduras como huevos, carnes, y pescados.",
        id: 2
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/albahaca_canela.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/albahaca_canela.png',
        title: 'Albahaca Canela',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Canela, aní­s", "#878e3d"],
        disponibilidad: ["Abril a Septiembre", "#006600"],
        description: "Potente desde su origen, canela, aní­s y resina. La increible combinación de matices que presenta, ayudan a realzar tanto platos de verduras como huevos, carnes, y pescados.",
        id: 3
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/Berro_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/Berro_2.png',
        title: 'Berro 98',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Herbal, ligeramente picante", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor herbal, picante y refrescante.",
        id: 4
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/brocoli_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/brocoli_2.png',
        title: 'Brocoli Now',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Suave col", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabroso, crujiente, fresco, con un suave y elegante sabor a col",
        id: 5
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/cilantro.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/cilantro.png',
        title: 'Cilantro 111',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Lima", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Destaca por su potente aroma y su increible sabor que nos recuerda la piel de la lima.",
        id: 6
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/comino_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/comino_2.png',
        title: 'Comino Lord',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Dulce, ligeramente anisado", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Exótico. Su carterístico sabor dulce, ligeramente anisado y su bonita composición destaca en los platos más sofisticados.",
        id: 7
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/eneldo_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/eneldo_2.png',
        title: 'Eneldo Tarc',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Dulzón, fresco y anisado", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Presenta un sabor único, dulzón, fresco y anisado. Resulta muy potente para su delicada apariencia. Sus características lo convierten en un gran aliado en la combinación con platos de pescados, carnes, ensaladas....",
        id: 8
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/espinaca_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/espinaca_3.png',
        title: 'Espinaca',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Crujiente y fresco aroma con un leve sabor amargo.",
        id: 9
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/guisante_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/guisante_2.png',
        title: 'Guisante 89',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "30g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Dulce, ligeramente ácido. Formado por una preciosa composición de de hojas y zarcillos trepadores que lo convierten en el perfecto acompañante de cualquier plato.",
        id: 10
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hinojo.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hinojo.png',
        title: 'Hinojo 347',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor dulce, anisado, crujiente, jugoso con un ligero toque astringente.",
        id: 11
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/mizuna_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/mizuna_2.png',
        title: 'Mizuna Púrpura',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Se caracteriza por un sabor y aroma muy intensos. Resulta ligeramente picante. Proporciona una sensación cálida que permanece en la memoria.",
        id: 12
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/mostaza_negra.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/mostaza_negra.png',
        title: 'Mostaza Negra',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Potencia de alto nivel, tiene un sabor intenso y muy picante que recuerda al wasabi. Resulta muy agradable ya que es una nota muy divertida que desaparece rápidamente sin perjudicar los demás sabores.",
        id: 13
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/mostaza_purpura_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/mostaza_purpura_3.png',
        title: 'Mostaza Roja',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "La mostaza roja es suculenta fresca y crujiente. Provoca un sabor picante y a la vez salado lleno de matices.",
        id: 14
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/pino.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/pino.png',
        title: 'Pino Pinus',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "10g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Crujiente con un agradable sabor a piñón, nos transporta al monte.",
        id: 15
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/puerro_.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/puerro_.png',
        title: 'Puerro Allium',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor similar a las cebollas, igualmente dulzón pero más suave y delicado.",
        id: 16
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/rabano_negro.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/rabano_negro.png',
        title: 'Rábano Negro',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Aromático, picante y fresco. Recuerda al  bosque y a la tierra, un lejano recuerdo a trufa, y más parecido al sabor de los hongos, pero con un suave toque picante, un conjunto de sensaciones por las que resulta especialmente atractivo.",
        id: 17
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/rabano_rosa_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/rabano_rosa_3.png',
        title: 'Rábano Rosa',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor que recuerda en cierto modo a la pimienta, intenso, picante y crujiente.",
        id: 18
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/remolacha_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/remolacha_2.png',
        title: 'Remolacha Wit',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "El caractéristico olor a tierra húmeda, su intenso sabor a tierra ligeramente dulce todo concentrado en un pequeño y atractivo envase.",
        id: 19
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/rucula.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/rucula.png',
        title: 'Rúcula',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Característico sabor a nuez muy potente y persistente final amargo.",
        id: 20
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/tatsoi_1.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/tatsoi_1.png',
        title: 'Tatsoi Ura',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "40g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Espinaca, mostaza, col. Textura suave, cremosa, delicada al paladar, con un sabor intenso y ligeramente picante.",
        id: 21
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/zanahoria.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/zanahoria.png',
        title: 'Zanahoria Loki',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Ligeramente amargo", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Textura que resulta suave en boca y al mismo tiempo sorprendentemente potente. Unas todavia alargadas y verdes hojas que recuerdan fielmente el sabor y aroma dulce e intenso de   una zanahoria sin zanahoria.",
        id: 22
      },
    ],
    Flores: [
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/abelia_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/abelia_2.png',
        title: 'Abelia Ton',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "45u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor ligeramente dulce, muy suave y delicada. Flor pequeña.",
        id: 0
      },
      /*{
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/flor_albahaca_purpura.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/flor_albahaca_purpura.png',
        title: 'Albahaca Basil',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "45u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Abril a Septiembre", "#006600"],
        description: "Sabor ligeramente anisado recuerda al limón. Flor pequeña.",
        id: 1
      },*/
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/begonia_rosa.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/begonia_rosa.png',
        title: 'Begonia 98',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "45u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor ácido, cítrico, ligeramente avinagrado.",
        id: 1
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/capuchina-naranja.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/capuchina-naranja.png',
        title: 'Capuchina',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "15u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor ácido, cítrico, textura crujiente.",
        id: 2
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/clavel_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/clavel_2.png',
        title: 'Clavel Now',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "34u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor dulce recuerda ligeramente al limón estimula la salibación.",
        id: 3
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/crisantemo_naranja.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/crisantemo_naranja.png',
        title: 'Crisantemo Lord',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "34u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor suave ligeramente amargo, aterciopelado en boca.",
        id: 4
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/geranio.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/geranio.png',
        title: 'Geranio Bull',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "45u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Aroma a canela, te, incienso, violeta. Sabor suave. Aterciopelado en boca.",
        id: 5
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/jazmin.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/jazmin.png',
        title: 'Jazmín Min',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "45u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor dulce, suave y aterciopelado en boca.",
        id: 6
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/pensamiento.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/pensamiento.png',
        title: 'Pequeño Pensamiento',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "34u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor dulce, suave. Textura aterciopelada.",
        id: 7
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/verbena_3.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/verbena_3.png',
        title: 'Verbena Pvt',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "10u."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Sabor y aroma muy neutros. Flor en ramillete formado por flores pequeñas fácilmente separable para usar solas o en conjunto.",
        id: 8
      },
    ],
    Hojas: [
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_capuchina.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_capuchina.png',
        title: 'Capuchina Hoja',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Textura que crujiente. Sabor refrescante y ligeramente picante.",
        id: 0
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_acelga_amarilla_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_acelga_amarilla_2.png',
        title: 'Acelga Amarilla',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Septiembre a Junio", "#006600"],
        description: "Aroma a tierra húmeda, dulce, de agradable sabor y textura suave a la vez que crujiente.",
        id: 1
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_mizuna_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_mizuna_2.png',
        title: 'Mizuna Púrpura',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Se caracteriza por un sabor y aroma muy intensos. Resulta ligeramente picante. Proporciona una sensación cálida que permanece en la memoria.",
        id: 2
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_mostaza_roja_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_mostaza_roja_2.png',
        title: 'Mostaza Roja',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "La mostaza roja es suculenta fresca y crujiente. Provoca un sabor picante y a la vez salado lleno de matices.",
        id: 3
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_remolacha_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_remolacha_2.png',
        title: 'Remolacha Wit',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "El caractéristico olor a tierra húmeda, su intenso sabor a tierra ligeramente dulce todo concentrado en un pequeño y atractivo envase.",
        id: 4
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_tatsoi_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_tatsoi_2.png',
        title: 'Tatsoi Ura',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Espinaca, mostaza, col. Textura suave, cremosa, delicada al paladar, con un sabor intenso y ligeramente picante.",
        id: 5
      },
      {
        imageURLConFondo:
          'https://static-germinia-es.web.app/assets/con-fondo/hoja_zanahoria_2.png',
        imageURLSinFondo:
          'https://static-germinia-es.web.app/assets/sin-fondo/hoja_zanahoria_2.png',
        title: 'Zanahoria Loki',
        width: '25%',
        characteristics: [
          ["Formato", "Bandeja"],
          ["Capacidad", "20g."],
          ["Agrupacion", "Cajas Cartón"],
          ["Unidades", "34"],
          ["Medidas", "24,5x38x31,5 cm"],
          ["Peso", ">2 kg"]
        ],
        sabor: ["Acído", "#878e3d"],
        disponibilidad: ["Todo el año", "#006600"],
        description: "Textura que resulta suave en boca y al mismo tiempo sorprendentemente potente. Unas todavia alargadas y verdes hojas que recuerdan fielmente el sabor y aroma dulce e intenso de una zanahoria sin zanahoria.",
        id: 6
      },
    ],
  }, // default value (aka initial value)
});

const mobileAnimationState = atom({
  key: 'mobileAnimationState',
  default: false,
});

const mobileDrawerState = atom({
  key: 'mobileDrawerState',
  default: false,
});

const desktopAnimationState = atom({
  key: 'desktopAnimationState',
  default: false,
});

const getSelectedItemState = selectorFamily({
  key: 'getSelectedItemState',
  get: ({ category, id = 1 }) => ({ get }) => {
    if (!category || !id) return null;
    return get(catalogsState)[category][Number(id)];
  },

  // optional set
  // set: (category, id) => ({ set }, newValue) => {
  //   set(myNumberState, newValue / category);
  // },
});

const getCatalogItemsState = selectorFamily({
  key: 'getCatalogItemsState',
  get: (category) => ({ get }) => {
    if (!category) return null;
    return get(catalogsState)[category];
  },

  // optional set
  // set: (category, id) => ({ set }, newValue) => {
  //   set(myNumberState, newValue / category);
  // },
});

export {
  catalogsState,
  mobileAnimationState,
  mobileDrawerState,
  desktopAnimationState,
  getSelectedItemState,
  getCatalogItemsState,
}