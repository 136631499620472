import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from "recoil";
import { getSelectedItemState, desktopAnimationState } from "../../state/catalogState";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { chunk } from "../../utils";
import { CSSTransition } from "react-transition-group";
import DesktopShareModal from "./DesktopShareModal";

import { Helmet } from "react-helmet";

import "./itemDescription.css";
import { useParams, useHistory } from "react-router-dom";

export default function ItemDescription(props) {

  let history = useHistory();
  const params = useParams();
  const item = useRecoilValue(getSelectedItemState(params)) || { item: { id: 1 } };
  const [animationInProp, setAnimationInProp] = useRecoilState(desktopAnimationState);
  const [shareModalOpen, setShareModalOpen] = useState(false);


  useEffect(() => {
    setAnimationInProp(true)
  }, [params, setAnimationInProp])

  const renderCharacteristics = () => {
    const characteristics = chunk(item.characteristics, 2);

    return (
      <>
        {characteristics.map(pair => (
          <CharacteristicWrapper key={pair[0][0]}>
            <Characteristic>
              {pair[0][0]}: {pair[0][1]} <br />
              {pair[1][0]}: {pair[1][1]}
            </Characteristic>
          </CharacteristicWrapper>
        ))}
      </>
    )
  }

  const handleModalOpen = () => {
    setShareModalOpen(true);
  }

  const goToContact = () => {
    history.push(`/contacto`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet>
        <title>{item.title} - Germinia</title>
        <meta name="description" content={item.description} />
        <meta property="og:title" content={`${item.title} - Germinia`} />
        <meta property="og:description" content={item.description} />
        <meta property="og:url" content={`https://germinia.es/catalogo/${params.category}/${params.id}`} />
        <meta property="og:image" content={item.imageURLConFondo} />
        <meta property="og:site_name" content="Germinia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={`${item.title} - ${params.category} - Germinia`} />
      </Helmet>
      <CSSTransition in={animationInProp} timeout={1000} classNames="description" appear mountOnEnter unmountOnExit >
        <Product imageSrc={item.imageURLSinFondo} >
          <ItemName>
            {item.title}
          </ItemName>
          <ItemDesc>
            <Description dangerouslySetInnerHTML={{ __html: item.description }} />
            <Buttons>
              {/* <Column style={{ marginRight: "1.4em"}}>
              <Button>Contacto</Button>
              <Button color={item.disponibilidad ? item.disponibilidad[1] : ""}>Disponibilidad: {item.disponibilidad ? item.disponibilidad[0] : ""}</Button>
            </Column>
            <Column>
              <Button>
                Compartir
            <ShareIcon icon={faShareAlt} />
              </Button>
            </Column> */}
              <Row>
                <Button onClick={goToContact}>Contacto</Button>
                <Button onClick={handleModalOpen}>
                  Compartir
            <ShareIcon icon={faShareAlt} />
                </Button>
              </Row>
              {/* <Button color={item.sabor ? item.sabor[1] : ""}>Sabor: {item.sabor ? item.sabor[0] : ""}</Button> */}
              <Button color={item.disponibilidad ? item.disponibilidad[1] : ""}>
                Disponibilidad: {item.disponibilidad ? item.disponibilidad[0] : ""}
              </Button>
            </Buttons>
          </ItemDesc>
          <CharacteristicsList>
            {renderCharacteristics()}
          </CharacteristicsList>
        </Product>
      </CSSTransition>
      {
        shareModalOpen && (
          <DesktopShareModal open={shareModalOpen} setModalOpen={setShareModalOpen} />
        )
      }
    </>
  )
}

const Product = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${props => props.imageSrc});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30vw;
  /* background-size: 50%; */
  /* background-size: 500px; */
  margin: 0 32px;
  margin-top: -12vw;
  padding-top: 19vw;
  /* margin-top: -30vh; */
  font-family: "Montserrat", sans-serif;
`;

const ItemName = styled.div`
  font-size: 4em;
  align-self: flex-start;
  /* margin-top: 250px; */
  color: #878e3d;
  font-family: "Caviar Dreams";
  min-width: calc(60vw - 64px);
  mix-blend-mode: difference;
`;

const ItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  font-family: "Montserrat";
  @media(min-width: 1200px) {
    max-width: 40vw;
  }
`;

const Description = styled.div`
  margin: 3em 0 2em;
  padding: 1.5em 2em;
  font-family: "Montserrat";
  font-weight: 300;
  line-height: 1.6;
  /* box-shadow: 0px 4px 35px -6px #07401861; */
  box-shadow: 0px 6px 32px -16px #07401861;
  color: #1d330d;
  background-color: #ffffffcc;
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  /* justify-content: space-between; */
  margin-bottom: 1em;
`;

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   `;

const Button = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding: 0.5em 1.5em;
  background: ${props => props.color || "#4B7D00"};
  cursor: ${props => !props.color ? "pointer" : "unset"};
  color: white;
  margin: .5em 0;
  box-shadow: 0 7px 20px -7px #627962;
  font-weight: 500;
  margin-right: 32px;
  /* justify-content: center; */
  place-content: center;
  width: fit-content;
  ${props => !props.color ? "background-image: linear-gradient(180deg, #8BC34A,#558B2F);" : null};
  `;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  /* margin: 0em 2em; */
`;

const ShareIcon = styled(FontAwesomeIcon)`
  margin-left: 1em;
`;

const CharacteristicsList = styled.div`
  display: flex;
  margin: 2em 0;
  font-weight: 300;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  justify-content: center;
  padding: 1.5em 0;
  width: 100%;
  @media(max-width: 1339px) {
    flex-direction: column;
    border: none;
  }
`;

const CharacteristicWrapper = styled.div`
  @media(max-width: 1339px) {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 1.5em 0;
    display: flex;
    padding-left: 64px;
  /* justify-content: center; */
  }
`;

const Characteristic = styled.div`
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 0 2em;

  @media(max-width: 1339px) {
    border-right: none;
    padding-left: 1em;
  }
`;