import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import GlideCarousel from './GlideCarousel';
import { CatalogTitleMobile } from "../Typography";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
  images: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function GalleryGrid(props) {
  const { classes, slideName, items } = props;

  return (
    <div className={classes.root}>
      <CatalogTitleMobile>
        {slideName}
      </CatalogTitleMobile>
      <GlideCarousel slideName={slideName} items={items} />
    </div >
  );
}

GalleryGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GalleryGrid);
