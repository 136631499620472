import { createGlobalStyle } from 'styled-components';

import CaviarDreams from './fonts/caviarDreams.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Caviar Dreams';
        src: local('Caviar Dreams'), local('CaviarDreams'),
        url(${CaviarDreams}) format('truetype');
        /* font-weight: 300; */
        /* font-style: normal; */
    }
`;