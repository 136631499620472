import React from 'react';
import { useParams, Route, useRouteMatch } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { getCatalogItemsState, catalogsState } from "../../state/catalogState";
// import ItemDetails from "../../components/mobile/ItemDetails";
// import { CSSTransition } from "react-transition-group";
import DetailsGlideCarousel from '../../components/mobile/DetailsGlideCarousel';
import GalleryGrid from '../../components/mobile/GalleryGrid';
import ItemDescription from '../../components/mobile/ItemDescription';

function ProductDetail(props) {
  const { category } = useParams();
  const [catalogs] = useRecoilState(catalogsState);

  let { path } = useRouteMatch() || { path: "/null/", url: "/null/" };
  
  const items = useRecoilValue(getCatalogItemsState(category));

  return (
    <>

      <DetailsGlideCarousel items={items} slideName={category} />

      <Route exact path={path}>
        <div className="mobileItemDetails">
          {/* <ItemDetails /> */}
          <ItemDescription />
        </div>
      </Route>

      {Object.keys(catalogs)
        .filter(catalogo => catalogo !== category)
        .map(catalogo => <GalleryGrid items={catalogs[catalogo]} slideName={catalogo} key={catalogo} />)}


    </>
  )
}


export default ProductDetail;