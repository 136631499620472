import firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/functions';
// import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCMbXJxKFNkrRxwsgSS4DL5B3aRnaDl4do",
  authDomain: "germinia-es.firebaseapp.com",
  databaseURL: "https://germinia-es.firebaseio.com",
  projectId: "germinia-es",
  storageBucket: "germinia-es.appspot.com",
  messagingSenderId: "329142613637",
  appId: "1:329142613637:web:35a5da460d154e8e125bec",
  // measurementId: "G-Y50MNPJ33N"
};

firebase.initializeApp(firebaseConfig);

export const app = firebase.app();
// export const auth = firebase.auth();
export const functions = firebase.functions();
// export const analytics = firebase.analytics();