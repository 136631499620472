import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import MuiTypography from '@material-ui/core/Typography';
import styled from 'styled-components';

const styles = (theme) => ({
  markedH2Center: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH3Center: {
    height: 4,
    width: 55,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH4Center: {
    height: 4,
    width: 55,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH6Left: {
    height: 2,
    width: 28,
    display: 'block',
    marginTop: theme.spacing(0.5),
    background: 'currentColor',
  },
});

const variantMapping = {
  h1: 'h1',
  h2: 'h1',
  h3: 'h1',
  h4: 'h1',
  h5: 'h3',
  h6: 'h2',
  subtitle1: 'h3',
};

function Typography(props) {
  const { children, classes, marked = false, variant, ...other } = props;

  return (
    <MuiTypography variantMapping={variantMapping} variant={variant} {...other}>
      {children}
      {marked ? (
        <span className={classes[`marked${capitalize(variant) + capitalize(marked)}`]} />
      ) : null}
    </MuiTypography>
  );
}

Typography.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  marked: PropTypes.oneOf([false, 'center', 'left']),
  variant: PropTypes.string,
};

export default withStyles(styles)(Typography);

export const CatalogTitleDesktop = styled.h2`
  font-family: "Caviar Dreams";
  font-size: 10vw;
  text-align: ${props => props.children === "Flores" ? "right" : "left"};
  margin: .5em 48px;
  font-weight: 400;
  position: relative;
  &:after {
    content: "";
    width: 2em;
    height: 3px;
    background: black;
    position: absolute;
    bottom: 0;
    left: ${props => props.children === "Flores" ? "unset" : "15%"};
    right: ${props => props.children === "Flores" ? "12%" : "unset"};
    top: 1.3em;
  }
`;

export const CatalogTitleMobile = styled.h2`
  font-family: "Caviar Dreams";
  font-size: 20vw;
  text-align: center;
  margin: .8em 0em 0em;
  font-weight: 400;
  position: relative;
  &:after {
    content: "";
    width: 2em;
    height: 3px;
    background: black;
    position: absolute;
    bottom: 0;
    left: ${props => props.children === "Flores" ? "unset" : "15%"};
    right: ${props => props.children === "Flores" ? "12%" : "unset"};
  }
`;

export const MenuLinkMobile = styled.h5`
  font-family: "Montserrat";
  font-size: 3em;
  font-weight: 400;
  position: relative;
  margin: 1em 0;
  /* margin-left: .5em; */
`;